@mixin single-social($background) {
  border-radius: 24px 0px 0px 24px;
  background-color: $background;
  margin-bottom: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  cursor: pointer;
  transition: 0.2s;
  align-items: center;
  justify-content: center;
}
@mixin hover-social($background) {
  border-radius: 0px;
  background: $background;
}



@keyframes rightAnimation  {
  0% {
    right: -50px;
  }
  100% {
    right: 0px;
  }
}

.social-links {
  &_wrapper {
    position: fixed;
    top: 20%;
    z-index: 10;
    right: 0px;
  }
  &_fb {
    @include single-social(#535f76);
    &:hover {
      @include hover-social(#3b5998);
    }
  }
  &_ig {
    @include single-social(#53539c);
    &:hover {
      @include hover-social(
        transparent linear-gradient(225deg, #405de6 0%, #c13584 100%) 0% 0%
          no-repeat padding-box
      );
    }
  }
  &_twitter {
    @include single-social(#65c2d6);
    &:hover {
      @include hover-social(#1da1f2);
    }
  }
  &_yt {
    @include single-social(#ef5a40);
    &:hover {
      @include hover-social(#c4302b);
    }
  }
  &_shop {
    @include single-social($yellow);
    &:hover {
      @include hover-social($orange);
    }
  }
}
.primary-btn-reverse {
    position: fixed !important;
    bottom: 0px;
    right: 100px;
    @include  btn-primary_reverse($orange);
}



.social-links_wrapper {
  > div  {
    margin-left: auto;
    span {
      display: none;
      font-size: 12px;
      text-align: center;
      color: white;
      margin-right: 10px;
    }
  a {
    display: flex;
    align-items: center;
  }
}
}
.social-links_wrapper {
  > div:hover {
    width: 160px;
    span {
      display: block;
    }
  }
}