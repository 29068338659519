@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
    font-family: 'Montserrat', sans-serif;
    padding-top: 100px;
}
a {
    text-decoration: none;
}

@media (max-width: 991px) {
    body {
        padding-top: 50px;
    }
}


#section-events {
    background: white;
    padding: 20px 0px;
}

.live-chat-btn {
    display: none !important;
}

.social-links_wrapper  {
    .material-icons {
        font-size: 25px;
    }
}
.background-main_page .slide-content {
    background: white;
}

.social-links_fb:hover .special-span {
    width: 100px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-size: 17px;
}

.footer-info-bar__text {
    a {
        color: white;
    }
}

@import "essentials";
@import "2-generic/slick-carousel";
@import '4-partials/partials';
@import '6-pages/pages';
@import '7-components/components';

