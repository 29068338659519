.project-gallery {
  margin-top: 50px;
}

.gallery-title {
}

.gallery-date {
  background: #ef5a40;
  font-size: 12px;
  position: relative;
  padding: 15px;
  border-radius: 31px 0px;
  transition: 0.2s;
  width: 150px;
  color: white;
  text-align: center;
}

.gallery-item {
  margin-bottom: 20px;
  &_wrap {
    padding: 15px;
    border: 1px solid #535f7640;
    border-radius: 24px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .special-class-display > img {
      border-radius: 24px;
      max-height: 300px;
      object-fit: cover;
      width: 100%;
    }
  }
}
.gallery-title {
  margin-top: 20px;
  margin-bottom: 20px;
}
.display-none-fancy {
  display: none;
}
.special-class-display {
  display: block !important;
}
.gallery-item_wrap {
  cursor: pointer;
  position: relative;
  transition: 0.4s;
    &:before {
      content: "Zobacz galerie";
      top: 0px;
      opacity: 0;
      right: 0px;
      left: 0px;
      transition: 0.2s;
      bottom: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      background: rgba(255, 255, 255, 0.6);
    }
    &:hover {
        &:before {
            opacity: 1;
        }
    }
  }

  .inside-expand {
    cursor: pointer;
    width: 60px;
    border-radius: 14px;
    height: 60px;
    background: $violet;
  }

.inside-expand {
    color: white;
    font-size: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .menu {
        font-size: 10px;
    }
}


.menu-icon-wrap {
    font-size: 43px;
    line-height: 29px;

}

.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0;
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0px!important;
  position: relative;
  text-align: left;
  vertical-align: middle;
}

.dropdown-toggle{
  color: #535F76;
}

.dropdown-toggle:hover{
  color: #535F76;
}
