.after-header_item {
    &__img {
    width: 100%;
    max-height: 400px;
    object-fit: cover; 
    }
    &__content {
        display: flex;
        flex-direction: column;
        align-items: start;
        > h2 {
            color: $orange;
            font-size: 30px;
            line-height: 45px;
            margin-bottom: 30px;
            font-weight: bold;
        }
    }
}

.page-post {
    margin-top: 100px;
}

.date-btn {
    @include primary-btn($blue);
    margin-bottom: 30px;
}
.page-post-content {
.single-excerpt > p {
    font-size: 14px;
    line-height: 24px;
    color: $violet-grey;
    margin: 30px 0px 40px 0px;
}
h1, h2, h3, h4, h5, h6 {
    color: $violet-grey;
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
}
p {
    font-size: 14px;
    color: $violet-grey;
    line-height: 24px;
    margin-bottom: 30px;
    font-weight: 300;
}
}

.single-sibling {
    text-decoration: none;
    &--wrapper {
        border: 1px solid rgba(83, 95, 118, 0.25);
        height: 100%;
        border-radius: 24px;
        position: relative;
    }
    &--body {
        display: flex;
        justify-content: center;
        padding: 20px;
        flex-direction: column;
        height: 100%;
        min-height: 300px;
    }
    &--navigator---preve {
        position: absolute;
        top: 0px;
        padding: 10px;
        font-size: 12px;
        line-height: 22px;
        color: white;
        border-radius: 24px 0px 24px 0px;
        background: $orange;
        left: 0px;
    }
    &--title {
        font-size: 16px;
        color: $violet-grey;
        line-height: 24px;
        font-weight: 700;
        margin-bottom: 20px;

    }
    &--navigator {
        position: absolute;
        top: 0px;
        padding: 10px;
        font-size: 12px;
        line-height: 22px;
        color: white;
        border-radius: 0px 24px 0px 24px;
        background: $orange;
        right: 0px;
    }
    .post-excerpt {
        font-size: 14px;
        color: $violet-grey;
        margin: 0px;
        line-height: 24px;
        font-weight: 300;
    }
}

.newest-heading {
    color: $violet;
    font-weight: bold;
    font-size: 20px;
    line-height: 50px;
    margin-bottom: 30px;
}

.recommand-image > img {
    max-height: 200px;
    width: 100%;
    object-fit: cover;
}
.reco {
    &--title {
        font-size: 13px;
        margin: 20px 0px;
        display: block;
        font-weight: bold;
        color: $violet-grey;
    }
    &--excerpt {
        font-size: 12px;
        color: $violet-grey;
        margin-bottom: 20px;
        line-height: 24px;
        font-weight: 200;
    }
    &--date {
        color: rgba(83, 95, 118, 0.5);
        font-size: 12px;
        border-bottom: 1px solid rgba(83, 95, 118, 0.2);
        margin-bottom: 40px;
        padding-bottom: 20px;

    }
}

.page-post-featured {
    margin-top: 40px;
}