.primary-btn_animatria {
  background: $violet;
  color: white;
  font-size: 13px;
  position: relative;
  padding: 21px 15px 8px 25px;
  border-radius: 31px 0px;
  transition: 0.2s;
  color: white;
  &:after {
    content: "";
    position: absolute;
    top: 7px;
    right: 0px;
    transition: 0.2s;
    border: 2px solid $yellow;
    left: 8px;
    bottom: -10px;
    width: 100%;
    border-radius: 31px 0px;
  }
  &:hover {
    color: white;
    opacity: 0.8;
    text-decoration: underline;
    &:after {
      border: 4px solid $yellow;
    }
  }
}

.btn-primary_regular {
  background: #ef5a40;
  font-size: 12px;
  position: relative;
  padding: 15px;
  border-radius: 31px 0px;
  transition: 0.2s;
  width: 150px;
  color: white;
  text-align: center;
}

.btn-shop-link {
  background: white;
    border-radius: 30px;
    color: #53539C;
    font-size: 14px;
    line-height: 21px;
    padding: 18px 36px;
}


@mixin btn-primary_reverse($background) {
  background: $background;
  color: white;
  font-size: 13px;
  position: relative;
  z-index: 10;
  padding: 10px 25px 18px 16px;
  border-radius: 0px 31px;
  transition: 0.2s;
  color: white;
  &:after {
    content: "";
    position: absolute;
    top: -7px;
    right: 0px;
    transition: 0.2s;
    border: 2px solid $yellow;
    border-radius: 0px 31px;
    left: 8px;
    bottom: 2px;
    width: 100%;

  }

  &:hover {
    color: white;
    opacity: 0.8;
    text-decoration: underline;
    &:after {
      border: 4px solid $yellow;
    }
  }
}

@mixin primary-btn($background) {
  background: $background;
    color: white;
    font-size: 13px;
    position: relative;
    padding: 15px 25px;
    border-radius: 31px 0px;
    transition: 0.2s;
    color: white;
}