.top-menu {
  display: flex;
  max-width: 1750px;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding: 25px;
  background: white;
}

#menu-header-menu,
#menu-header-menu-ang,
#menu-lang-menu-2,
#menu-lang-menu-1 {
  display: flex;
  padding: 0px;
  margin: 0px;
  justify-content: space-between;
  list-style-type: none!important;;

  @media (max-width: 991px) {
    padding-top: 15px;
  }

  > li {
    padding: 10px 8px;
    > a {
      max-width: 236px;
      font-size: 12px;
      font-weight: bold;
      text-align: center;
      color: $violet-grey;
      padding: 0px;
      white-space: break-spaces;

      @media (max-width: 991px) {
        text-align: left;
      }
    }
  }
}

#top-menu {
  position: fixed;
  background: white;
  top: 0px;
  right: 0px;
  z-index: 10;
  border-bottom: 1px solid rgba(83, 95, 118, 0.25);
  left: 0px;
}
.main-menu {
  flex: 0 0 55%;

  &--mobile {
    display: none;

    @media (max-width: 991px) {
      display: block;

      #left-menu {
        display: block;
        width: 100%;
        position: static;
      }

      #menu-left-menu > li > a,
      #menu-left-menu-1 > li > a,
      #menu-left-menu-ang > li > a
       {
        margin-bottom: 8px;
        font-size: 14px;
      }

      #menu-left-menu .dropdown-menu,
      #menu-left-menu-ang .dropdown-menu,
      #menu-left-menu-1 .dropdown-menu {
        position: static !important;
        padding: 0 8px;
      }

      .left-shop {
        margin-top: 0;
      }

      .menu-wrapper_spacing {
        height: auto;
        padding: 15px;
      }

      .close-left-menu {
        display: none;
      }
    }
  }
}

.container-hd {
  max-width: 1920px;
  margin: auto;
}

.menu-expand {
  cursor: pointer;
  max-width: 60px;
}

//usunac jak skoncze

#wpadminbar {
  display: none !important;
}
@media (min-width: 991px) {
  #menu-header-menu .depth_0,
  #menu-header-menu-ang .depth_0,
  #menu-lang-menu-2 .depth_0,
  #menu-lang-menu-1 .depth_0 {
    transform: translateY(4px) !important;
  }
}
.depth {
  &_0 {
    top: calc(100% + 1.5rem) !important;
    border-radius: 0 24px 24px 24px;
    padding: 45px;
    min-width: 370px;
    border: 0;

    @media (max-width: 991px) {
      position: static !important;
      width: 100%;
      transform: unset !important;
      border-radius: 0 0 24px 24px;
      margin-top: 15px;
      min-width: unset;
    }

    ul {
      list-style: none;
      padding-inline-start: 0;
    }

    .nav {
      &-link {
        color: inherit;

        &--bold {
          font-size: 16px;
          font-weight: 600;

          @media (max-width: 991px) {
            font-size: 12px;
          }
        }
      }

      &-item.show {
        & > .nav-link {
          text-decoration: none!important;
        }
      }

      &-item {
        & > .nav-link {
          @media (max-width: 991px) {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
  }

  &_1 {
    top: 80%;
    transform: translateY(-50%);
    min-height: 100%;
    width: 100%;
    left: 100%;
    padding: 30px 15px;
    border-radius: 24px;

    @media (max-width: 991px) {
      position: static !important;
      transform: unset !important;
      border-radius: 0 0 24px 24px;
      border: 0;
    }

    .nav-item {
      font-size: 14px;
      font-weight: 400;
      transition: 0.3s;

      &:hover {
        font-weight: 600;
      }
    }

    .nav-link {
      color: $violet-grey;
    }
  }
}

@media (max-width: 991px) {
  .main-logo {
    max-width: 20%;
  }
}
.phone-call {
  display: flex;
  align-items: center;
  > span {
    font-size: 14px;
    > a {
      color: $violet-grey;
    }
  }
}

.fake-prev_opinion,
.fake-next_opinion {
  display: none !important;
}

.menu-wrapper_spacing::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.menu-wrapper_spacing::-webkit-scrollbar {
  width: 10px;
  background-color: $violet;
}

.menu-wrapper_spacing::-webkit-scrollbar-thumb {
  background: transparent linear-gradient(225deg, #405de6 0%, #c13584 100%) 0%
    0% no-repeat padding-box;
  border-radius: 0px;
  padding: 2px;
  border: none;
}

.dropdown-toggle {
  display: flex;
  align-items: center;

  &:after {
    display: none;
  }

  svg {
    margin: 0 0 0 10px;
    display: block;
    min-width: 16px;
    fill: $red;
    transition: 0.3s;
  }
}

.depth {
  &_0 {
    svg {
      fill: #fff;

      &.mobile-svg {
        @media (max-width: 991px) {
          transform: rotate(90deg);
        }
      }
    }
  }

  &_1 {
  }
}

.rotate {
  &-hor,
  &-vert {
    transform: rotate(-180deg);

    &.mobile-svg {
      transform: rotate(-90deg) !important;
    }
  }
}

#menu-lang-menu {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

#menu-lang-menu .depth_0 .nav-item > .nav-link {
  display: block;
  align-items: center;
  justify-content: center;
  color: #535F76;
  font-weight: 400;
  font-size: 15px;
}
#menu-lang-menu .depth_0 {
  position: absolute !important;
  top: 0px !important;
}
@media (max-width: 991px) {
  #menu-lang-menu .depth_0 {
    top: 15px !important;
  }
  .section-carousel {
    margin-top: 50px !important;
  }
}
