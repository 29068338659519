.event-heading__flex {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;
  .h2 {
    font-size: 40px;
    line-height: 50px;
    margin: 0px;
    font-weight: bold;
  }
  .h4 {
    font-size: 40px;
    line-height: 50px;
    font-weight: 300;
    margin: 0px;
  }
  .excerpt {
    font-size: 16px;
    line-height: 30px;
    color: $violet-grey;
    margin-top: 40px;
    font-weight: 500;
    text-align: center;
  }
  .reference {
    text-align: left;
    margin-bottom: 30px;
    display: block;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 8px;
    color: #535f76;
    text-transform: uppercase;
    opacity: 0.5;
  }
  p {
    font-size: 16px;
    line-height: 30px;
    color: $violet-grey;
    margin-top: 0px;
    font-weight: 600;
    text-align: center;
  }
  strong {
    font-weight: bold;
  }
}

.btn-shop {
  display: flex;
  align-items: center;
  > img {
    margin-right: 5px;
  }
}

// palety kolorow danego eventu...

.pink-palette {
  .h2 {
    color: #f2b8b8;
  }
  .h4 {
    color: $violet-grey;
  }
}
.blue-palette {
  .h2 {
    color: $blue;
  }
  .h4 {
    color: $violet-grey;
  }
}
.yellow-palette {
  .h2 {
    color: $yellow;
  }
  .h4 {
    color: $violet-grey;
  }
}
.orange-palette {
  .h2 {
    color: $orange-light;
  }
  .h4 {
    color: $violet-grey;
  }
}
.red-palette {
  .h2 {
    color: #ef5a40;
  }
  .h4 {
    color: $violet-grey;
  }
}
.violet-pallete {
  .h2 {
    color: $violet;
  }
  .h4 {
    color: $blue;
  }
}

.right-image-container {
  align-items: center;
  color: $violet-grey;
  font-size: 14px;
  margin-top: 50px;
  line-height: 30px;
  p {
    margin-bottom: 25px;
  }
  a {
    color: $orange;
    font-weight: bold;
  }
  img {
    width: 100%;
  }
  ul {
    list-style-type: none;
    padding: 0px;
    li::before {
      content: "•";
      font-size: 23px;
      margin-right: 5px;
      color: $orange;
    }
  }
  .event_content {
    @media (min-width: 991px) {
      max-width: 500px;
      margin: auto;
    }
  }
}
.left-image-container {
  align-items: center;
  margin-top: 65px;
  color: $violet-grey;
  font-size: 14px;
  line-height: 30px;
  p {
    margin-bottom: 25px;
  }
  a {
    color: $orange;
    font-weight: bold;
  }
  img {
    width: 100%;
  }
  ul {
    list-style-type: none;
    padding: 0px;
    li::before {
      content: "•";
      font-size: 23px;
      margin-right: 5px;
      color: $orange;
    }
  }
  .event_content {
    @media (min-width: 991px) {
      max-width: 570px;
      margin: auto;
    }
  }
}

.newest-posts {
  margin-top: 100px;
}

.event-link {
  color: $orange;
  text-decoration: underline;
}

.newest-single {
  &__text {
    .h4 {
      color: $violet-grey;
      font-size: 14px;
      font-weight: bold;
      line-height: 24px;
      margin: 20px 0px;
    }
    .post-excerpt {
      color: $violet-grey;
      font-size: 12px;
      line-height: 24px;
      margin-bottom: 25px;
    }
    .slide-date {
      color: rgba(83, 95, 118, 0.5);
      font-size: 10px;
    }
  }
  &__image {
    img {
      width: 100%;
      max-height: 270px;
      object-fit: cover;
      border-radius: 12px;
    }
  }
}
#newest-heading {
  margin-bottom: 25px;
}
.event-prices_heading {
  margin-bottom: 40px;
}
#service-prices {
  margin-top: 50px;
}
.single-service {
  display: flex;
  background: white;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(83, 95, 118, 0.25);
  margin-bottom: 20px;
  border-radius: 24px;
  height: 80px;
  p {
    margin: 0px;
  }
  > div {
    flex: 0 0 25%;
    text-align: center;
  }
}

.contact-people--mail-text {
  a {
    color: $violet;
  }
}

.service-name {
  &_name {
    color: $violet-grey;
    font-size: 18px;
    margin-bottom: 5px;
    font-weight: bold;
  }
  &_capacity {
    color: rgba(83, 95, 118, 0.5);
    font-size: 14px;
  }
  &_time {
    border-radius: 20px 0px;
    background: $yellow;
    margin-right: 20px;
    color: white;
    padding: 15px;
  }
  &_price {
    font-size: 28px;
    line-height: 50px;
    color: $violet-grey;
    font-weight: bold;
  }
}

.single-price:nth-child(3) {
  .service-name_time {
    background: $orange-light;
  }
}
.single-price:nth-child(4) {
  .service-name_time {
    background: $orange;
  }
}

@media (max-width: 991px) {
  .page-id-159 .event-heading__flex {
    margin-top: 0px;
  }
  .page-id-159 #section-opinion .heading-opinion {
    margin-bottom: 40px;
  }
  .right-image-container {
    flex-direction: column-reverse;
    margin-top: 10px;
  }
  .left-image-container {
    margin-top: 10px;
  }
}

@media (max-width: 991px) {
  .breadcrumbs-menu {
    margin-top: 10px;
  }
  .event-heading__flex p {
    font-size: 15px;
    line-height: 30px;
    text-align: left;
  }
  .event-heading__flex {
    margin-top: 10px;
  }
  .breadcrumbs-menu {
    font-size: 9px;
  }
  .depth_1 {
    min-height: unset;
  }
  .depth_0 {
    padding: 15px;
  }
  .depth_0 ul {
    padding: 0px;
  }
  .single-service {
    flex-direction: column;
    height: unset;
    justify-content: center;
    max-width: 70%;
    margin: 20px auto;
    > div {
      margin: 20px 0px;
    }
  }
  .single-service > div {
    flex: auto;
  }
  .single-additional-offer img {
    max-height: 165px;
    object-fit: scale-down;
  }
  #menu-header-menu > li,
  #menu-header-menu-ang > li,
  #menu-lang-menu-2 > li,
  #menu-lang-menu-1 > li {
    flex: unset;
  }
}

.bottom-price_info {
  text-align: center;
  color: $violet-grey;
  font-size: 16px;
  line-height: 50px;
}
.bottom-price_price {
  color: $yellow;
  font-weight: bold;
}

.single-additional-offer_heading {
  margin: 15px 0px;
  font-size: 16px;
  color: white;
  line-height: 26px;
}

.single-additional-offer {
  img {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .single-additional-offer {
    max-width: 85%;
    margin: 0px auto 20px auto;
  }
}

.single-additional-prices {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left-price {
    text-align: center;
    .children {
      font-size: 14px;
      color: white;
      margin: 0px;
    }
    .price {
      color: white;
      font-size: 15px;
      line-height: 20px;
      font-weight: bold;
    }
  }
  .right-price {
    text-align: center;
    .children {
      font-size: 14px;
      color: white;
      margin: 0px;
    }
    .price {
      color: white;
      font-size: 15px;
      font-weight: bold;
      line-height: 20px;
    }
  }
}
.inner-flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.single-post {
  #breadcrumbs {
    margin-top: 25px;
  }
}
@media (max-width: 991px) {
  .page-post {
    margin-top: 30px;
  }
  #section-opinion .single-slide {
    height: auto;
  }
}

.recommand-border {
  border: 1px solid #535f7640;
  padding: 5px;
  position: relative;
  border-radius: 24px;
  background: white;
  height: 100%;
}


@media (min-width: 991px) {
.single-additional-offer .inner-flex {
  min-height: 300px;
}
}
.single-additional-offer {
  margin-bottom: 30px;
}

.single-additional-offer:nth-child(1) .inner-flex {
  border-radius: 24px;
  .single-additional-prices {
    padding: 0px 15px;
  }
  .single-additional-offer_heading {
    padding: 0px 15px;
  }
}

.single-additional-offer:nth-child(1n -15) .inner-flex {
  background-color: $violet;
  border-radius: 24px;
}
.single-additional-offer:nth-child(2n -15) .inner-flex {
  background-color: $blue;
  border-radius: 24px;
}
.single-additional-offer:nth-child(3n -15) .inner-flex {
  background-color: $orange;

  border-radius: 24px;
}
.single-additional-offer:nth-child(4n -15) .inner-flex {
  background-color: $yellow;

  border-radius: 24px;
}
.single-additional-offer:nth-child(5n -15) .inner-flex {
  background-color: #f2b8b8;

  border-radius: 24px;
}
.single-additional-offer:nth-child(6n -15) .inner-flex {
  background-color: $additional-background-a;

  border-radius: 24px;
}
.single-additional-offer:nth-child(7n -15) .inner-flex {
  background-color: $blue;

  border-radius: 24px;
}
.single-additional-offer:nth-child(8n -15) .inner-flex {
  background-color: $orange;

  border-radius: 24px;
}
.single-additional-offer:nth-child(9n -15) .inner-flex {
  background-color: $yellow;

  border-radius: 24px;
}
.single-additional-offer:nth-child(10n -15) .inner-flex {
  background-color: $additional-background-c;

  border-radius: 24px;
}
.single-additional-offer:nth-child(11n -15) .inner-flex {
  background-color: $additional-background-b;

  border-radius: 24px;
}

.single-additional-offer:nth-child(12n -15) .inner-flex {
  background-color: $orange;

  border-radius: 24px;
}

.single-additional-offer:nth-child(13n -15) .inner-flex {
  background-color: $blue;

  border-radius: 24px;
}
.single-additional-offer:nth-child(14n -15) .inner-flex {
  background-color: $yellow;
  border-radius: 24px;
}

.single-additional-offer .inner-flex {
  border: 1px solid transparent;
  img {
    border-radius: 22px;
    max-height: 150px;
    object-fit: cover;
  }
  .single-additional-prices {
    padding: 0px 15px;
  }
  .single-additional-offer_heading {
    padding: 0px 15px;
  }
}


@media (max-width: 991px) {
  .single-additional-offer .inner-flex img {
    max-height: 130px;
  }
  .single-additional-offer_heading {
    margin: 5px 0px;
  }
  .single-additional-prices .left-price .price {
    font-size: 17px;
  }
  .single-additional-prices .right-price .price {
    font-size: 17px;
  }
  .event-template-default {

  }
  .newest-posts {
    margin-top: 50px;
    .opinion-flex {
      flex-direction: column;
      .reference {

      }
      .opinion-flex_button {
        width: 100%;
        .slider-btn {
          width: 100%;
        }
      }
    }
  }
  #section-map .opinion-flex_heading {
    margin-top: 100px;
  }
}

.event-heading__flex {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;
  background: #ffffff;
  padding: 40px;
}

.right-image-container{
  background: #ffffff;
  padding: 40px;
}

.left-image-container{
  background: #ffffff;
  padding: 40px;
}

.left-image-container p {
  margin-bottom: 25px;
  text-align: justify;
}

.right-image-container p {
  margin-bottom: 25px;
  text-align: justify;
}

@media (max-width: 991px) {
  .event-heading__flex {
    padding: 0px;
  }

  .right-image-container, .left-image-container {
    padding: 0px 0px 10px 10px;
  }
}