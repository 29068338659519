.contact {

  &-title {
    display: flex;
    flex-direction: column;
    font-size: 40px;
    font-weight: 800;
    text-align: center;

    .text {
      &-blue {
        font-weight: 400;
      }
    }

    &--row {
      justify-content: center;
    }
  }

  &-heading {

    &--container {
      padding-top: 30px;
      font-size: 16px;
      font-weight: 600;
      color: $violet-grey;
    }

    &--row {
      justify-content: center;
    }

    &--strong {
      font-weight: 700;
    }
  }

  &-people {
    $contact-figure-dimension: 270px;

    @media (max-width: 1199px) {
      $contact-figure-dimension: 192px;
    }

    @media (max-width: 991px) {
      $contact-figure-dimension: 132px;
    }

    @media (max-width: 767px) {
      $contact-figure-dimension: 492px;
    }

    @media (max-width: 375px) {
      $contact-figure-dimension: 327px;
    }

    @media (max-width: 320px) {
      $contact-figure-dimension: 272px;
    }

    @media (max-width: 280px) {
      $contact-figure-dimension: 232px;
    }

    &--container {
      padding-top: 60px;
    }

    &--body {
      padding: calc(1.5rem / 2);
    }

    &--figure {
      height: $contact-figure-dimension;
      width: 100%;
      border-radius: 50%;
      margin: 0;
      @include imageFigure();

      @media (max-width: 1199px) {
        height: 192px;
      }

      @media (max-width: 991px) {
        height: 132px;
      }

      @media (max-width: 767px) {
        height: 492px;
      }

      @media (max-width: 375px) {
        height: 327px;
      }

      @media (max-width: 320px) {
        height: 272px;
      }

      @media (max-width: 280px) {
        height: 232px;
      }

      @media (max-width: 575px) {
        border-radius: 0;
      }
    }

    &--round {
      position: relative;

      &:before,
      &:after {
        content: '';
        position: absolute;
      }

      &:before {
        top: 50%;
        left: 50%;
        width: $contact-figure-dimension;
        height: $contact-figure-dimension;
        border-radius: 50%;
        border: 3px solid;
        transform: translate(calc(-50% + 21px), calc(-50% + 21px));
        z-index: 2;
        border: 3px solid #53539C;

        @media (max-width: 1199px) {
          height: 192px;
          width: 192px;
        }

        @media (max-width: 991px) {
          height: 132px;
          width: 132px;
        }

        @media (max-width: 767px) {
          height: 492px;
          width: 492px;
        }

        @media (max-width: 575px) {
          display: none;
        }
      }

      &:after {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        z-index: 3;
        background-color: #53539C;

        @media (max-width: 575px) {
          display: none;
        }
      }
    }

    &:nth-of-type(1n) .contact-people--round {
      /*&:before {
        border-color: $yellow;
      }*/

      &:after {
        top: 90%;
        left: 15%;
        transform: translateY(-50%);
       // background-color: $contact-circle-blue;
      }
    }

    &:nth-of-type(2n) .contact-people--round {
      /*&:before {
        border-color: $contact-circle-blue;
      }*/

      &:after {
        top: 25%;
        left: 90%;
        transform: translateY(-50%) translateX(-50%);
        //background-color: $contact-circle-orange;
      }
    }

    &:nth-of-type(3n) .contact-people--round {
      /*&:before {
        border-color: $contact-circle-pink;
      }*/

      &:after {
        top: 50%;
        left: 0;
        transform: translateY(calc(-50% + 21px)) translateX(21px);
        //background-color: $orange;
      }
    }

    &:nth-of-type(4n) .contact-people--round {
      /*&:before {
        border-color: $violet;
      }*/

      &:after {
        top: 83%;
        left: 83%;
        transform: translateY(calc(-50% + 21px)) translateX(calc(-50% + 21px));
        //background-color: $contact-circle-pink;
      }
    }

    &--img {
      @include imageCover();
    }

    &--name {
      font-size: 22px;
      font-weight: 700;

      &-col {
        padding-top: 30px;
      }
    }

    &--desc {
      font-size: 14px;
    }

    &--space {
      padding-top: 15px;
    }

    &--icon {
      width: 27px;
      height: 25px;
    }

    &--mail-text {
      font-size: 16px;
      font-weight: 600;
      color: $violet-grey;
    }
  }

  &-form {

    &--container {
      padding-top: 120px;
    }

    &--title {
      font-size: 30px;

      .text-violet {
        font-weight: 700;
      }

      &-row {
        margin-bottom: 30px;
      }
    }

    &--left {

      &-body {
        padding-left: calc(1.5rem / 2);
        padding-right: calc(1.5rem / 2);
      }

      &-text {
        font-size: 16px;
        color: $violet-grey;
        margin-bottom: 60px;
      }
    }

    &--right {

      .wpcf7-list-item {
        margin: 0;
      }

      .wpcf7-list-item-label {
        font-size: 14px;
        color: $contact-label;
      }


      textarea {
        resize: none;
      }

      .form {

        &-row {
          margin-bottom: calc(1.5rem / 2);

          @media (max-width: 767px) {
            margin-bottom: 0;
          }
        }

        &-control {
          min-height: 50px;
          border-radius: 12px;
          border: 1px solid $contact-control-border;

          @media (max-width: 767px) {
            margin-bottom: calc(1.5rem / 2);
          }
        }

        &-label {
          font-size: 14px;
          color: $contact-label;
        }

        &-check-input {
          @media (max-width: 575px) {
            margin-bottom: calc(1.5rem / 2);
          }
        }

        &-submit {
          @include makeAnchor($violet, #fff, $yellow);
          border: 0;
          padding: 15px 30px;

          &:before {
            z-index: 2;
          }

          &--icon {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }
      }
    }
  }

  &-blog {

    &--heading {

      &-container {
          @media (max-width: 575px) {
            margin-top: 30px;
          }
      }

      &-rich {
        font-size: 16px;
        font-family: 'Prompt', sans-serif;
        font-weight: 400;
        letter-spacing: 8px;
        color: $violet-grey;
        opacity: .5;
        text-transform: uppercase;
      }
    }

    &--title {
      display: flex;
      flex-direction: column;
      font-size: 40px;
      font-weight: 700;

      .text-blue {
        font-weight: 400;
      }
    }

    &--row {
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
    }

    &--button {
      @include makeAnchor($violet, #fff, $yellow);
      padding: 15px 30px;
      font-size: 14px;
      display: block;

      @media (max-width: 440px) {
        margin: 15px 0;
      }

      &:before {
        transition: .3s;
      }

      &:hover {
        color: #fff;
        text-decoration: none;
        
       &:before {
         border-width: 4px;
       } 
      }
    }

    &--single {

      @media (max-width: 767px) {
        margin-bottom: 15px;
      }

      &-body {
        padding-left: calc(1.5rem / 2);
        padding-right: calc(1.5rem / 2);
      }

      &-figure {
        @include imageFigure();
        height: 270px;
        border-radius: 12px;
        margin-bottom: 30px;

        @media (max-width: 1199px) {
          height: 192px;
        }

        @media (max-width: 991px) {
          height: 132px;
        }

        @media (max-width: 767px) {
          height: 492px;
        }

        @media (max-width: 575px) {
          height: 327px;
        }

        @media (max-width: 320px) {
          height: 272px;
        }
      }

      &-img {
        @include imageCover();
      }

      &-title {
        font-size: 16px;
        color: $violet-grey;
        margin-bottom: 30px;
      }

      &-excerpt {
        font-size: 14px;
        color: $violet-grey;
        margin-bottom: 60px;
      }

      &-date {
        color: rgba($violet-grey, .5);
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 600px){
  .contact-people--figure {
    height: 150px;
    width: 150px;
    margin: 0 auto;
  }
}
