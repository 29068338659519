.top-slider {
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: space-between;
}

.slide-content {
  padding-left: 1.5rem;
  flex: 0 0 40%;
  display: flex;
  flex-direction: column;
  .slide-centered {
    max-width: 900px;
    padding-bottom: 2rem;
    padding-right: 2rem;
    @media (min-width: 991px) {
      
      margin-top: 122px;
    }
  }
  .h1 {
    color: $violet;
    font-size: 50px;
    font-weight: 600;
    line-height: 70px;
  }
  .h3 {
    font-size: 36px;
    line-height: 70px;
    margin-top: 10px;
    color: $blue;
    font-weight: 300;
  }
  .slider-btn {
    margin-top: 60px;
  }
  .header-top {
    text-align: left;
    margin-bottom: 30px;
    display: block;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 8px;
    color: #535f76;
    text-transform: uppercase;
    opacity: 0.5;
  }
  .desc {
    color: #535f76;
    opacity: 0.75;
    margin-top: 60px;
    font-size: 16px;
    line-height: 30px;
  }
}

#section-events {
  .slide-content {
    .h1 {
      margin: 0px;
      font-size: 40px;
      line-height: 60px;
    }
    .h3 {
      font-size: 40px;
      line-height: 70px;
      margin: 0px;
    }
  }
}

#carousel-offer {
  .slick-list {
    padding: 150px 0px 0px 0px;
  }
  .slick--prev {
    @include slick-prev;
    @include align-left-absolute;
    top: 55%;
  }
  .slick--next {
    @include slick-next;
    @include align-right-absolute;
    top: 55%;
  }
}
.no-found-page {
  text-align: center;
}
body {
  background: url("../img/background-main.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.single-offer {
  border: 1px solid #535f7640;
  border-radius: 25px;
  position: relative;
  padding: 65px 0px 60px 0px;
  transition: 0.3s;
  position: relative;
  > .single-offer_image {
    position: absolute;
    top: -45px;
    z-index: 1000;
    transition: 0.3s;
    right: 50%;
    transform: translate(50%, -50%);
  }
  .h2 {
    font-size: 26px;
    line-height: 36px;
    font-weight: bold;
    color: $violet;
  }
  .h4 {
    color: $blue;
    font-weight: 400;
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 30px;
  }
  .desc {
    color: $violet-grey;
    font-size: 14px;
    line-height: 24px;
    padding: 0px 20px;
  }
  &:hover {
    background: $violet;
    .desc {
      color: white;
    }
    .h2 {
      color: white;
    }
    h4 {
      color: white;
    }
    > .single-offer_image {
      transform: rotate(-15deg) translate(50%, -50%);
    }


    &:before {
      content: "";
      position: absolute;
      top: -15px;
      transition: 0.3s;
      right: 15px;
      left: -15px;
      bottom: 15px;
      border: 2px solid $yellow;
      border-radius: 25px;
    }
  }
}

.section-carousel {
  margin-top: 120px;
}

.fullwidth-video {
  margin-top: 150px;
  position: relative;
  margin-bottom: 100px;
  transition: height 1s;
  .promote-video {
    width: 100%;
    height: 300px;
    transition: height 1s;
    object-fit: cover;
  }
  .video-overlay {
    background: rgba(30, 106, 232, 0.35);
    cursor: pointer;
    position: absolute;
    content: "";
    top: 0px;
    transition: height 1s;
    right: 0px;
    left: 0px;
    bottom: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .btn-play {
      background: white;

      padding: 13px 14px;
      border-radius: 16px 0px;
    }
    .video-heading {
      margin-top: 25px;
      letter-spacing: 8px;
      color: #ffffff;
      font-size: 16px;
      line-height: 19px;
      opacity: 0.75;
    }
    &:hover {
      background: rgba(30, 106, 232, 0.5);
    }
  }
}
@media (max-width: 1250px) {
  .single-offer_background {
    display: none !important;
  }
}
.slick-slide:nth-child(1) {
  .single-offer_background {
    position: absolute;
    z-index: 10;
    transition: 0.3s;
    top: -40px;
    right: 35%;
    transform: translate(50%, -50%);
  }
}
.slick-slide:nth-child(2) {
  .single-offer_background {
    position: absolute;
    z-index: 10;
    transition: 0.3s;
    top: -10px;
    right: 50%;
    transform: translate(50%, -50%);
  }
  .single-offer {
    .h2 {
      color: $orange;
    }
    .h4 {
      color: $yellow;
    }
  }
  .single-offer:hover {
    background: $orange;
    &:before {
      border-color: $yellow;
    }
    .h2 {
      color: white;
    }
    .h4 {
      color: white;
    }

  }
}

.slick-slide:nth-child(3) {
  .single-offer_background {
    position: absolute;
    z-index: 10;
    transition: 0.3s;
    top: -10px;
    right: 50%;
    transform: translate(50%, -50%);
  }
}

.opinion-flex {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  &_heading {
    .reference {
      text-align: left;
      margin-bottom: 30px;
      display: block;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 8px;
      color: #535f76;
      text-transform: uppercase;
      opacity: 0.5;
    }
    .h2 {
      font-size: 40px;
      line-height: 50px;
      color: $violet;
      font-weight: bold;
      margin-top: 20px;
    }
    .h4 {
      font-size: 40px;
      line-height: 50px;
      color: $blue;
      font-weight: 300;
    }
  }
}

#section-opinion {
  .slick-slider {
    margin: 0px;
  }
  .slick-list {
    padding: 90px 200px 50px 200px !important;
  }
  .single-slide {
    border: 1px solid #535f7640;
    padding: 50px 15px 30px 15px;
    position: relative;
    border-radius: 24px;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    background: white;
    p {
      font-size: 15px;
      line-height: 25px;
      margin: 0px;
    }
  }
  .quotes-top {
    position: absolute;
    top: 0px;
    border-radius: 25px;
    display: flex;
    width: 80px;
    height: 80px;
    font-size: 40px;
    color: $violet;
    transform: translate(0px, -50%) rotate(-15deg);
    align-items: center;
    justify-content: center;
  }

  .slick--next_opinion {
    @include slick-next;
    position: relative;
  }
  .slick--prev_opinion {
    @include slick-prev;
    position: relative;
    margin-right: 20px;
  }
  .opinion-flex_button {
    display: flex;
    padding: 0px;
    align-items: center;
    .slider-btn {
      margin-right: 30px;
    }
  }
  .slick-dots {
    display: flex;
    list-style-type: none;
    align-items: center;
    justify-content: center;
    button {
      text-indent: -9999px;
      width: 22px;
      height: 4px;
      background: #8f9ea780;
      margin-right: 10px;
      border: none;
    }
  }
  .slick-active {
    button {
      background: $red;
    }
  }
  .single-slide {
    &:hover {
      border-color: $violet;
    }
  }
}
#section-partners {
  margin-top: 110px;
  .single-partner {
    img {
      width: 100%;
      background: white;
      border-radius: 12px;
    }
  }
  #partners {
    margin-top: 25px;

    > div {
      margin-bottom: 24px;
    }
  }
}

#section-blog {
  .slide-image {
    border-radius: 12px;
    height: 100%;
    object-fit: cover;
  }
  .opinion-flex {
    margin-top: 100px;
  }
  .slick-list {
    padding: 40px 180px 50px 180px !important;
  }
  .single-slide {
    border: 1px solid #535f7640;
    padding: 20px;
    position: relative;
    border-radius: 24px;
    background: white;
  }
  .slick-slider {
    margin: 0px;
  }
  .slick-dots {
    display: flex;
    list-style-type: none;
    align-items: center;
    justify-content: center;
    button {
      text-indent: -9999px;
      width: 22px;
      height: 4px;
      background: #8f9ea780;
      margin-right: 10px;
      border: none;
    }
  }
  .slick-active {
    button {
      background: $red;
    }
  }

  .slick--next_opinion {
    @include slick-next;
    position: relative;
  }
  .slick--prev_opinion {
    @include slick-prev;
    position: relative;
    margin-right: 20px;
  }
  .opinion-flex_button {
    display: flex;
    padding: 0px;
    align-items: center;
    .slider-btn {
      margin-right: 30px;
    }
  }
  .blog-slide_flex {
    display: flex;
  }
  .slide-figure {
    margin-bottom: 0px;
    margin-right: 20px;
    flex: 0 0 40%;
    border-radius: 12px;
    max-height: 350px;
  }
  .slide-content__text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .slide-title {
    color: $violet-grey;
    font-size: 18px;
    font-weight: bold;
    line-height: 25px;
    margin-bottom: 20px;
  }
  .blog-content {
    > p {
      font-size: 14px;
      margin: 0px;
      font-weight: 300;
      line-height: 24px;
      color: $violet-grey;
    }
  }
}
.btn-blog-link {
  text-decoration: none;
}
#section-blog .btn-blog-link {
  text-decoration: none;
  transition: 0.2s;
  &:hover {
    .slide-title {
      color: $orange;
      transition: 0.2s;
      text-decoration: underline;
    }
    .single-slide {
      border-color: $orange;
      transition: 0.2s;
    }
    .slide-figure {
      overflow: hidden;
    }
    .slide-image {
      transition: 0.2s;

      vertical-align: middle;
      display: inline-block;
      transform: scale(1.5);
    }
  }
}
@media (min-width: 991px) {
.no-padding {
  padding: 0px;
}
}
.opinion-author {
  display: flex;
  margin-top: 40px;
  align-items: center;
  &_notes {
    .author {
      color: $violet-grey;
      font-size: 16px;
    }
    .role {
      font-size: 14px;
      margin: 0px;
      color: rgba(83, 95, 118, 0.7);
    }
  }
  &_figure {
    margin: 0px;
    padding-right: 10px;
  }
}

@media (max-width: 1450px) {
  .background-main_page #section-opinion .slick-list {
    padding: 90px 0px 50px 0px !important;
  }
  .background-main_page #section-blog .slick-list {
    padding: 40px 0px 50px 0px !important;
  }
}

@media (max-width: 1300px) {
  .write-to-us {
    display: none;
  }
}

@media (max-width: 991px) {
  #menu-lang-menu {
    display: none;
  }
}

@media (max-width: 1150px) {
  body {
    background: transparent;
  }
  .menu-expand {
    display: none;
  }
  .main-menu {
    flex: 0 0 70%;
  }
}

.change-lang {
  margin-top: 1rem;
  
}
#menu-lang-menu-mobile {
  color: white;
  list-style-type: none;
  padding: 0px;
  span {
    color: white;
  }
}


@media (min-width: 991px) {
.slick-track {
  display: flex !important;
}
}

.slick-slide {
  height: inherit;
  > div {
    height: 100%;
    .btn-blog-link {
      height: 100%;
      .single-slide {
        height: 100%;
      }
    }
  }

}
#menu-lang-menu .depth_0 {
  padding: 0px;
  min-width: unset;
  border: 1px solid rgba(83, 95, 118, 0.25);
}
#menu-lang-menu-1 .depth_0,  #menu-lang-menu-2 .depth_0 {
  padding: 0px;
  min-width: unset;
  border: 1px solid rgba(83, 95, 118, 0.25);
  top: 0px !important;
}

.menu-expand-mobile {
  cursor: pointer;
}

.blog-slide_flex {
  height: 100%;
}
@media (min-width: 992px) {
  .menu-expand-mobile {
    display: none;
  }
}
@media (max-width: 991px) {
  #section-events {
    margin-top: 50px;
  }
  .force-static {
    position: static;
  }
  .main-menu {
    position: fixed;
    bottom: 0px;
    right: 0px;
    overflow: auto;
    left: 0px;
    display: none;
    background: white;
    top: 76px;
  }
  .top-menu {
    padding: 15px;
  }


  #menu-header-menu, #menu-header-menu-ang, #menu-lang-menu-2,  #menu-lang-menu-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    > li {
      flex: 0 0 90%;
      width: 100%;
      border-bottom: 1px solid #535f76;
      padding: 15px 15px;

      > a {
        max-width: 100%;
      }
    }
  }
  .top-slider {
    margin-top: 30px;
    flex-direction: column-reverse;
    .slide-content {
      flex: 0 0 100%;
    }
  }
  .slick-slider {
    margin: 0px;
  }
  .slide-content .slide-centered {
    padding-right: 0px;
    text-align: center;
    margin-top: 40px;
    padding: 0px 15px;
  }
  .slide-content .desc {
    margin-top: 30px;
  }
  .slide-content {
    .h1 {
      font-size: 30px;
      line-height: 50px;
    }
    .h3 {
      font-size: 25px;
      line-height: 40px;
    }
  }
  .social-links_wrapper {
    display: none;
  }
  #nav-icon1 span,
  #nav-icon3 span,
  #nav-icon4 span {
    display: block;
    position: absolute;
    height: 3px;
    margin: 15px;
    margin-left: 0px;
    width: 100%;
    background: $orange;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  #nav-icon3 {
    width: 30px;
    height: 45px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }
  #nav-icon3 span:nth-child(1) {
    top: 0px;
  }

  #nav-icon3 span:nth-child(2),
  #nav-icon3 span:nth-child(3) {
    top: 8px;
  }

  #nav-icon3 span:nth-child(4) {
    top: 16px;
  }

  #nav-icon3.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
  #nav-icon3.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }

  #nav-icon3.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  #nav-icon3.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  #nav-icon3.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
  .slide-content .slider-btn {
    margin-top: 10px;
  }
  .slick-slide {
    padding: 0px;
  }
  .slide-content .header-top {
    text-align: center;
  }
  .slide-content {
    padding-left: 0px;
  }
  .slider-btn {
    width: 90%;
    margin: auto;
  }
  .fullwidth-video {
    margin: 80px 0px 40px;
  }
  #section-opinion,
  #section-blog,
  #section-partners {
    position: relative;
    .opinion-flex {
      position: static;
      align-items: center;
      .opinion-flex_button {
        position: absolute;
        bottom: -60px;
        right: 0px;
        padding: 0px 15px;
        left: 0px;
        .slider-btn {
          width: 60%;
          margin-left: 0px;
          margin-right: auto;
        }
      }
      .opinion-flex_heading {
        flex: 0 0 100%;
      }
    }
  }
  #section-partners {
    margin-top: 135px;
  }
  .footer {
    background: none;
  }
  #section-blog .opinion-flex {
    &_heading {
      text-align: center;
      .reference {
        text-align: center;
      }
    }
  }
  #section-partners {
    .slider-btn {
      width: 100% !important;
    }
  }
  .opinion-flex {
    &_heading {
      text-align: center;
      .h2 {
        font-size: 30px;
        line-height: 50px;
        text-align: center;
      }
      .h4 {
        font-size: 25px;
        text-align: center;
        line-height: 40px;
      }
      .reference {
        text-align: center;
      }
    }
  }
  #section-opinion .quotes-top {
    transform: translate(50%, -50%) rotate(-15deg);
    right: 50%;
  }
  #section-blog .blog-slide_flex {
    flex-direction: column;
  }
  #section-blog .slide-figure {
    flex: 100%;

    margin-right: 0px;
    .slide-image {
      max-height: 200px;
    }
}
#section-blog .slide-image {
  width: 100%;
  padding-bottom: 25px;
}
}


@media (max-width: 1150px) {
  #menu-header-menu > li > a {
    font-size: 10px;
  }
  .phone-call > span > a {

  }
}
@media (max-width: 767px ) {
  .breadcrumbs-menu {
    overflow-x: auto;
  }
}

@media (max-width: 991px) {
  .event-heading__flex .h2 {
    font-size: 30px !important;
    line-height: 35px !important;
  }
  .event-heading__flex .h4 {
    font-size: 30px !important;
    line-height: 35px !important;
  }
}
.page-event {
  .container {
    padding: 0px;
  }
}

#section-map {
  margin: 40px 0px;
  .opinion-flex_heading {
    text-align: center;
    margin: 0px auto 30px auto;
  }
}


#wpgmza_map {
  float: none !important;
  cursor: grab;
}

@media (max-width: 991px) {
  #wpgmza_map {
    height: 200px !important;
  }
}

.show {
  a[aria-expanded="true"] > svg {
    transform: rotate(180deg);
  }
}
.depth_0 {
  .show {
    svg {
      transform: rotate(180deg);
    }
  }
}

.show {

  > .depth_1 {
    display: block !important;
  }
}
.dropdown-menu {
  display: none;
}
.dropdown .depth_0.show {
  display: none;
}
.dropdown.show .depth_0.show {
  display: block !important;
}

.czb {
  background: #fff;
  display: inline-block;
  margin: 0;
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0px!important;
  position: relative;
  text-align: left;
  vertical-align: middle;
}



.gallery__item {
  margin: 0px;
  position: relative;
  
}

#slider-firm {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(7, 6vw);
  grid-gap: 20px;
}


#slider-firm-bottom {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 2vw);
  grid-gap: 20px;
}
.gallery-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0px;
}

.gallery__item:nth-child(1) {
  grid-column-start: 2;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 3;
  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: -10px;
    right: 10px;
    bottom: -10px;
    border: 2px solid $blue;
    z-index: 3;
    border-radius: 12px 0px;
  }
}
.gallery__item:nth-child(2) {
  grid-column-start: 10;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 3;
  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: -10px;
    right: -40px;
    bottom: -10px;
    border: 2px solid $yellow;
    z-index: 3;
    border-radius: 0px 12px;
  }
}

.gallery__item:nth-child(3) {
  grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 5;
    &:before {
      content: '';
      position: absolute;
      top: 10px;
      left: -10px;
      right: 10px;
      bottom: -10px;
      border: 2px solid #F2B8B8;
      z-index: 3;
      border-radius: 0px 12px;
    }
}

.gallery__item:nth-child(4) {
  grid-column-start: 4;
  grid-column-end: 11;
  grid-row-start: 3;
  grid-row-end: 5;
  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: -10px;
    right: 10px;
    bottom: -10px;
    border: 2px solid #F48025;
    z-index: 3;
    border-radius: 12px 0px;
  }
}



.gallery__item:nth-child(5) {
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 5;
  grid-row-end: 7;
  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: -10px;
    right: 10px;
    bottom: -10px;
    border: 2px solid #EF5A40;
    z-index: 3;
    border-radius: 0px 12px;
  }
}
.gallery__item:nth-child(6) {
  grid-column-start: 5;
  grid-column-end: 11;
  grid-row-start: 5;
  grid-row-end: 7;
  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: -10px;
    right: 10px;
    bottom: -10px;
    border: 2px solid #535F76;
    z-index: 3;
    border-radius: 12px 0px;
  }
}



#slider-firm-bottom {
  
.gallery__item:nth-child(1) {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 4;
  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: -10px;
    right: 10px;
    bottom: -10px;
    border: 2px solid $blue;
    z-index: 3;
    border-radius: 12px 0px;
  }
}
.gallery__item:nth-child(2) {
  grid-column-start: 10;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 3;
  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: -10px;
    right: -40px;
    bottom: -10px;
    border: 2px solid $yellow;
    z-index: 3;
    border-radius: 12px;
  }
}

.gallery__item:nth-child(3) {
  grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 5;
    &:before {
      content: '';
      position: absolute;
      top: 10px;
      left: -10px;
      right: 10px;
      bottom: -10px;
      border: 2px solid #F2B8B8;
      z-index: 3;
      border-radius: 12px;
    }
}

.gallery__item:nth-child(4) {
  grid-column-start: 4;
  grid-column-end: 11;
  grid-row-start: 3;
  grid-row-end: 5;
  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: -10px;
    right: 10px;
    bottom: -10px;
    border: 2px solid #F48025;
    z-index: 3;
    border-radius: 12px;
  }
}



.gallery__item:nth-child(5) {
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 5;
  grid-row-end: 7;
  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: -10px;
    right: 10px;
    bottom: -10px;
    border: 2px solid #EF5A40;
    z-index: 3;
    border-radius: 12px;
  }
}
.gallery__item:nth-child(6) {
  grid-column-start: 5;
  grid-column-end: 11;
  grid-row-start: 5;
  grid-row-end: 7;
  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: -10px;
    right: 10px;
    bottom: -10px;
    border: 2px solid #535F76;
    z-index: 3;
    border-radius: 12px;
  }
}

}



#slider-firm-bottom {
  
  .gallery__item:nth-child(1) {
    grid-column-start: 1;
      grid-column-end: 5;
      grid-row-start: 3;
      grid-row-end: 7;
    &:before {
      content: '';
      position: absolute;
      top: 10px;
      left: -10px;
      right: 10px;
      bottom: -10px;
      border: 2px solid $blue;
      z-index: 3;
      border-radius: 12px;
    }
  }
  .gallery__item:nth-child(2) {
    grid-column-start: 5;
      grid-column-end: 9;
      grid-row-start: 1;
      grid-row-end: 6;
    &:before {
      content: '';
      position: absolute;
      top: 10px;
      left: -10px;
      right:  10px;
      bottom: -10px;
      border: 2px solid $yellow;
      z-index: 3;
      border-radius: 12px;
    }
  }
  
  .gallery__item:nth-child(3) {
    grid-column-start: 9;
    grid-column-end: 13;
    grid-row-start: 2;
    grid-row-end: 7;
      &:before {
        content: '';
        position: absolute;
        top: 10px;
        left: -10px;
        right: 10px;
        bottom: -10px;
        border: 2px solid #F2B8B8;
        z-index: 3;
        border-radius: 12px;
      }
  }
  
  .gallery__item:nth-child(4) {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 7;
    grid-row-end: 11;
    &:before {
      content: '';
      position: absolute;
      top: 10px;
      left: -10px;
      right: 10px;
      bottom: -10px;
      border: 2px solid #F48025;
      z-index: 3;
      border-radius: 12px;
    }
  }
  
  
  
  .gallery__item:nth-child(5) {
    grid-column-start: 5;
      grid-column-end: 9;
      grid-row-start: 6;
      grid-row-end: 13;
    &:before {
      content: '';
      position: absolute;
      top: 10px;
      left: -10px;
      right: 10px;
      bottom: -10px;
      border: 2px solid #EF5A40;
      z-index: 3;
      border-radius: 12px;
    }
  }
  .gallery__item:nth-child(6) {
    grid-column-start: 9;
    grid-column-end: 13;
    grid-row-start: 7;
    grid-row-end: 11;
    &:before {
      content: '';
      position: absolute;
      top: 10px;
      left: -10px;
      right: 10px;
      bottom: -10px;
      border: 2px solid #535F76;
      z-index: 3;
      border-radius: 12px;
    }
  }
  
  }

  .video-height {
    height: 70vh !important;
  }