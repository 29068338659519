.footer {
    margin-top: 100px;
    background: url("../img/footer-background.png");
    background-repeat: no-repeat;
    background-position: -180px 0px;
  }
  #footer-container {
    display: flex;
    margin: 20px 0px 20px auto;
    max-width: 1100px;
    align-items: center;
    justify-content: space-between;
    .footer-right {
        display: flex;
        align-items: center;
        .phone-call {
            margin-right: 20px;
        }

    }
  }

.footer-left {
    flex: 0 0 30%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .social-media_footer {
        display: flex;
        > a {
            border: 1px solid #8F9EA7;
            border-radius: 12px;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0px 10px;
        }
    }
}
  
.footer-info-bar {
    margin-top: 60px;
    background: $violet;
    
    &__text {
        color: white;
        font-size: 13px;
        line-height: 26px;
        opacity: 0.7;
        letter-spacing: 0.26px;
        padding: 35px 0px;
        margin: 0px;
        text-align: center;
    }
}

.heron-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0px;
    p {
        margin: 0px;
        color: #3D3D3D;
        font-size: 14px;
        line-height: 21px;
        padding-right: 50px;
    }
}

@media (max-width: 991px) {
    .footer {
        margin-top: 150px;
    }
}