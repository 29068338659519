.single-job {

    &--img {
      display: flex;
      justify-content: center;
      position: relative;
      max-height: 418px;

      @media (max-width: 767px) {
        margin-bottom: 60px;
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        z-index: 2;
      }

      &:before {
        top: 50%;
        left: 50%;
        transform: translate(calc(-50% - 28px), calc(-50% + 28px));
        border: 3px solid $blue;
      }

      &:after {
        top: 60%;
        left: 50%;
        height: 72px;
        width: 72px;
        border-radius: 50%;
        transform: translateX(100%);
        background-color: $single-job-circle;

        @media (max-width: 1199px) {
          transform: translateX(50%);
        }
      }

      &:before,
      figure {
        height: 418px;
        border-radius: 0 150px 0 150px;
        width: 100%;
      }

      figure {
        @include imageFigure();
      }

      img {
            @include imageCover();
      }
    }

  &--content {

    &-body {
      padding-left: .75rem;
      padding-right: .75rem;
    }
  }

  &--title {
    font-size: 30px;
    color: $violet-grey;
  }

  &--content {
    color: $violet-grey;

    li::marker {
      color: $orange;
    }
  }
}

.fb-like {
  padding: 0;
}
.post-type-archive-job {
.breadcrumbs-menu   {
  display: none !important;
}
}
