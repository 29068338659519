#left-menu {
    position: fixed;
    top: 0px;
    overflow: hidden;
    left: 0px;
    bottom: 0px;
    background: $violet;
    z-index: 11;
    width: 0px;
    transition: 0.3s;
}

.menu-width {
    width: 30% !important;
}

.menu-wrapper_spacing {
    padding: 20px 10% 50px 10%;
    height: 100vh;
    overflow-x: hidden;
}

#menu-left-menu, #menu-left-menu-1, #menu-left-menu-ang-1, #menu-left-menu-ang {
    padding: 0px;
    margin-bottom: 40px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    list-style-type: none;
    > li > a {
        color: white;
        padding: 0px 0px 0px 5px;
        font-size: 18px;
        margin-bottom: 30px;
        line-height: 22px;
        
    }
}
.close-left-menu {
    color: white;
    font-size: 35px;
    margin: 20px 0px 30px 0px;
    cursor: pointer;
}

.left-shop {
    margin-top: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    &_text {
        color: white;
        font-size: 16px;
        letter-spacing: 0px;
        line-height: 30px;
    }
}
.left-contact {
    margin-top: 25px;
    color: white;
    .owner {
        font-size: 14px;
    }
    .adres-info {
        list-style-type: none;
        padding: 0px;
        margin-bottom: 35px;
         > li {
            color: rgba(255, 255, 255, 0.75);
            margin-bottom: 10px;
            font-size: 14px;
        }

    }
}
.social-heading {
    color: white;
    font-size: 16px;
}
.lang-wrapper {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding-bottom: 20px;  
}

.left-social {
    display: flex;
    align-items: center;
;
    a {
        border: 1px solid rgba(255, 255, 255, 0.75);
        border-radius: 12px;
        margin-right: 15px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        > img {
            max-width: 16px;
        }
    }
    .social-heading {

    }
}

#menu-left-menu, #menu-left-menu-1, #menu-left-menu-ang-1, #menu-left-menu-ang {
    #nav-item-322 {
        position: relative !important;
        background: $violet !important;
        padding: 0px;
    }
    #submenu-560 {
        background: $violet;
    }
    .dropdown-menu {
        position: relative !important;
        position: relative !important;
        background: $violet !important;
        padding: 0px;
        transform: unset !important;
        top: -25px !important;
    }

}

#menu-lang-menu-mobile-1 a {
	color: white;


}

#menu-lang-menu-mobile-1 {
	padding: 0px;
	list-style-type: none;
}