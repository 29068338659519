$violet-grey: #535F76;
$violet: #53539C;
$yellow: #F7B520;
$blue: #58BCD6;
$red: #E94F36;
$orange: #EF5A40;
$orange-light: #F48025;
$additional-background-a: #A8A8CD;
$additional-background-b:#B1E0EA;
$additional-background-c:#F9BF91;
$additional-background-d:#FBDA8F;
$additional-background-e:#F8DBDB;

$contact-circle-blue: #65C2D6;
$contact-circle-orange: #F48025;
$contact-circle-pink: #F2B8B8;
$contact-control-border: #D6D6D6;
$contact-label: #202625;

$single-job-circle: #37428D;