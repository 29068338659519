.job {

  &-row {
    justify-content: center;
  }

  &-single {
    margin-bottom: 30px;

    &--img {
      max-width: 50%;

      @media (max-width: 767px) {
        max-width: 100%;
        margin-bottom: .75rem;
      }
    }

    &--row {
      align-items: center;
    }

    &--date {
      
      span {
        display: block;
        border-radius: 31px 0 31px 0;
        background-color: $contact-circle-blue;
        color: #fff;
        padding: 15px 30px;
        font-size: 12px;
        margin-bottom: 30px;
      }
    }

    &--content {
      color: $violet-grey;
    }

    &--title {
      font-weight: 700;
      margin-bottom: 15px;
      font-size: 22px;

      &-pre {
        font-weight: 400;
      }
    }
  }

  &-why {

    &--row {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    &--body {
      height: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      &-colorful {
        padding-left: .75rem;
        padding-right: .75rem;
        justify-content: center;
        height: auto;
        text-align: center;

        .row {
          width: 100%;
          justify-content: center;
          margin: 0;
        }
      }
    }

    &--title {
      font-size: 40px;
      display: flex;
      flex-direction: column;

      @media (max-width: 991px) {
        word-break: break-word;
      }

      .text-violet {
        font-weight: 700;
      }
    }

    &--big {
      min-height: 730px;

      @media (max-width: 767px) {
        min-height: 350px;
        margin-bottom: .75rem;
      }
    }

    &--radius {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      border-radius: 24px;
      color: #fff;

      @media (max-width: 991px) {
        padding-top: .75rem;
        padding-bottom: .75rem;
      }

      @media (max-width: 767px) {
        margin-bottom: .75rem;
      }
    }

    &--half {
      min-height: 350px;
    }

    &--space {
      padding-top: 30px;

      @media (max-width: 767px) {
        padding-top: 0;
      }
    }

    &--violet {
      background-color: $violet;
      height: 100%;
    }

    &--orange {
      background-color: $contact-circle-orange;
    }

    &--pink {
      background-color: $contact-circle-pink;
    }
    
    &--blue {
      background-color: $contact-circle-blue;
    }

    &--yellow {
      background-color: $yellow;
    }

    &--strong {
      display: block;
      font-size: 20px;
      font-weight: 700;
      margin: 15px 0;
    }

    &--text {
      font-size: 14px;
    }
  }
}