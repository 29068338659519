.heading-opinion {
    margin-bottom: 100px;
}

.single-opinion_container {
    margin-bottom: 100px;
}

// .single-recommand_container {
//     img {
//         max-width: 
//     }
// }

@media (max-width: 991px) {
    .single-opinion_container {
        margin-top: 30px;
        margin-bottom: 60px;
    }
    .single-recommand_container {
        margin-bottom: 15px;
    }
}