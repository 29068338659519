.slick-slide {
	&:focus {
		outline: none;
	}
}

.slick-slider {
	margin:0 -15px;
}
.slick-slide {
	padding:10px;
	margin-right:15px;
	margin-left:15px;
}

#carousel-offer {
	.slick-slide {
		text-align: center;
	}
}