@import 'breadcrumbs';

.text {

  &-violet {
    color: $violet;
  }

  &-blue {
    color: $blue;
  }

  &-rich {
    color: $violet-grey;
  }

  &-white {
    color: #fff;
  }
}