// Center block
@mixin center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

@mixin font-smoothing() {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin font-family( $font: $font__main ) {
	font-family: $font__main;
}
@mixin slick-prev() {

    color: white;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background: $blue;
    &:before {
      position: absolute;
      content: "";
      top: 5px;
      transition: 0.5s;
      right: -5px;
      left: 5px;
      bottom: -5px;
      border: 2px solid #f7b520;
      border-radius: 50%;
    }
    &:hover {
      opacity: 0.8;

      &:before {
        border: 4px solid $yellow;
      }
    }
}
@mixin align-right-absolute() {
	position: absolute;
    right: 0px;
    top: 50%;
}
@mixin align-left-absolute() {
	position: absolute;
    left: 0px;
    top: 50%;
}

@mixin slick-next() {
    
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 45px;
    height: 45px;
    transition: 0.2s;
    border-radius: 50%;
    color: white;
    transition: 0.2s;
    z-index: 2;
    background: $blue;
    &:before {
      position: absolute;
      content: "";
      top: 5px;
      transition: 0.2s;
      right: -5px;
      left: 5px;
      bottom: -5px;
      border: 2px solid $yellow;
      border-radius: 50%;
    }
    &:hover {
      opacity: 0.8;

      &:before {
        border: 4px solid $yellow;
      }
    }
  }

  @mixin breadcrumb-palette($main, $secondary) {
    .animatria-logo {
      background: $main;
      color: $main;
  }
  .current-item {
      color: $secondary;
  }
      a {
      color: rgba(83, 95, 118, 0.25);
      &:hover {
          color: $secondary;
      }
  }
  }

@mixin body-text( $level: normal, $body-text: $body-text, $device-size: false ) {
	@if type-of($body-text) != "map" {
		@error "`#{$body-text}` is not a valid value for $values. It must be a list.";
	}

	$size: map_get($body-text, $level);

	@if type-of($size) == "map" {

		@if $device-size == false {
			@each $device, $values in $size {

				@if $device != "small" {
					@include mq($from: $device) {
						font-size: map_get($values, "font-size");
						line-height: map_get($values, "line-height");
					}
				} @else {
					font-size: map_get($values, "font-size");
					line-height: map_get($values, "line-height");
				}
			}
		} @else {
			$device_values: map_get($size, $device-size);

			font-size: map_get($device_values, "font-size");
			line-height: map_get($device_values, "line-height");
		}
	}
}

@mixin imageFigure {
  position: relative;
  overflow: hidden;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

@mixin image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

@mixin imageContain {
  @include image();
  height: 100%;
  object-fit: contain;
}

@mixin imageCover {
  @include image();
  min-height: 100%;
  object-fit: cover;
}

@mixin makeAnchor($bgc, $color, $beforeColor) {
  position: relative;
  border-radius: 31px 0 31px 0;
  background-color: $bgc;
  color: $color;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 31px 0 31px 0;
    border: 2px solid $beforeColor;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% + 10px), calc(-50% + 10px));
  }
}


@mixin quotes-color($background, $quoteColor, $border-color)  {


    font-size: 40px;
    background: $background;
    color: $quoteColor;
    &:before {
      content: "";
      position: absolute;
      top: -10px;
      right: 0px;
      transition: 0.2s;
      border: 2px solid $border-color;
      left: -10px;
      bottom: 10px;
      width: 100%;
      border-radius: 25px;
    }
  
}

.quotes-yellow {
  @include quotes-color($yellow, $violet, $blue);
}

.quotes-blue {
  @include quotes-color($blue, $orange, $violet);
}
.quotes-violet {
  @include quotes-color($violet, $contact-circle-pink, $yellow);
}