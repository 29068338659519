.breadcrumbs-menu {
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    opacity: 0.9;
    text-align: center;
    .current-item {
        color: #F2B8B8;
    }

    a {
        color: rgba(83, 95, 118, 0.25);
        &:hover {
            color: #F2B8B8;
        }
    }
}
.breadcrumb-next {
    color: rgba(83, 95, 118, 0.25);
}
.animatria-logo {
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #E44330;
    color: #E44330;
    position: relative;
    &:before {
        content: '';
        top: 3px;
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: white;
        z-index: 2;
        right: 3px;
    }
}

.breadcrumbs-menu.pink-palette {
    @include breadcrumb-palette('#E44330', '#F2B8B8')

}

.breadcrumbs-menu.blue-palette {
    @include breadcrumb-palette($blue, $blue);
}
.breadcrumbs-menu.yellow-palette {
    @include breadcrumb-palette($yellow, $yellow);
}
.breadcrumbs-menu.orange-palette {
    @include breadcrumb-palette($orange-light, $orange-light);
}
.breadcrumbs-menu.red-palette {
    @include breadcrumb-palette('#EF5A40', '#EF5A40');
}
.breadcrumbs-menu.violet-pallete {
    @include breadcrumb-palette($violet, $violet);
}