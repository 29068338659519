.reuse-heading__flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
  .h2 {
    font-size: 40px;
    color: $violet;
    line-height: 50px;
    font-weight: bold;
  }
  .h4 {
    color: $blue;
    font-size: 40px;
    line-height: 50px;
  }
}
.news-search {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
  &_label--search {
    font-size: 14px;
    line-height: 18px;
    color: $violet-grey;
    margin-right: 15px;
  }
  &_input {
    border-radius: 50px;
    border: 1px solid rgba(83, 95, 118, 0.25);
    height: 60px;
    width: 300px;
    padding: 15px;
  }
  &_filter {
    color: $blue;
    margin-right: 10px;
  }
  &_label--sort {
    font-size: 14px;
    line-height: 18px;
    color: $violet-grey;
    margin-right: 15px;
  }
  &_sort {
    border-radius: 50px;
    border: 1px solid rgba(83, 95, 118, 0.25);
    height: 60px;
    padding: 0px 50px 0px 15px;
    background: url("../img/arrow-sort.png") no-repeat left;
    -webkit-appearance: none;
    background-position-x: 90%;
  }
}

.search-flex {
  position: relative;
  margin-right: 15px;
  .news-search_icon {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 60px;
    background: $blue;
    color: white;
    bottom: 0px;
    border-color: $blue;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.margin-bottom {
    margin-bottom: 20px;
}
.single-news {
  display: flex;
  border: 1px solid #535f7640;
  padding: 20px;
  position: relative;
  border-radius: 24px;
  height: 100%;
  background: white;
  &__text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .slide-date {
        background: #ef5a40;
    font-size: 12px;
    position: relative;
    padding: 15px;
    border-radius: 31px 0px;
    transition: 0.2s;
    width: 150px;
    color: white;
    text-align: center;
    }
  }
  .slide-figure {
      margin: 0px 15px 0px 0px;
      flex: 0 0 40%;
      max-height: 420px;
      > img {
        height: 100%;
        object-fit: cover;
      }
  }
  .blog-content {
      p {
          margin: 0px;
          font-size: 14px;
          line-height: 24px;
          color: $violet-grey;
          font-weight: 300;
      }
      .slide-title {
        line-height: 30px;
        font-size: 22px;
        color: $violet-grey;
        margin-bottom: 30px;
        font-weight: bold;
      }
  }
}

.pagination_news {
    align-items: center;
    justify-content: flex-end;
    .page-item {
      margin-left: 15px;
       width: 60px;
       position: relative;
       height: 60px;
       border-radius: 50%;
       border: 1px solid rgba(83, 95, 118, 0.25);
       > span, a  {
         padding: 0px;
         border-radius: 50%;
         width: 60px;
         border: none;
         height: 60px;
         position: absolute;
         top: 0px;
         right: 0px;
         bottom: 0px;
         left: 0px;
       }
    }
  }

.page-links {
    position: relative;
    display: block;
    padding: 0px;
    border-radius: 50%;
    width: 60px;
    border: none;
    height: 60px;
    position: absolute;
    display: flex;
    top: 0px;
    text-decoration: none;
    color: $violet-grey;
    right: 0px;
    transition: 0.2s;
    bottom: 0px;
    align-items: center;
    left: 0px;
    justify-content: center;
    &:hover {
        color: white;
        background: $violet;
        border-color: $violet;
    }
}

.page-links.current {
    color: white;
    background: $violet;
    border-color: $violet;
}



.prev-page {
    
}
.next.page-links {
    @include slick-next();
}
.prev.page-links {
    @include slick-prev();
}

@media (max-width: 991px) {
  .single-news {
    flex-direction: column;
    > .slide-figure {
      margin: 0px;
      > img {
        width: 100%;
        margin-bottom: 20px;
        max-height: 240px;
        object-fit: cover;
      }
    }
  } 
  .news-search {
    flex-direction: column;
    height: 260px;
    margin-bottom: 30px;
    justify-content: space-around;
  }
  .news-search_sort {
    width: 100%;
  }
  .search-flex {
    width: 100%;
    margin: 0px;
  }
  .news-search_input {
    width: 100%;
  }
}
