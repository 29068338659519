.category-heading-taxonomy {
    font-size: 40px;
    line-height: 50px;
    font-weight: 300;
    text-align: center;
    margin: 30px 0px;
    color: $violet;
}
.small-box {
    margin-bottom: 30px;
.single--product {
    border:  1px solid rgba(83, 95, 118, 0.25);
    padding:  10px;
    border-radius: 15px;
    margin-bottom: 15px;
    background: white;
    height: 100%;
    .btn-home {
        margin-top: 40px;
        display: block;
        text-align: center;
        max-width: 200px;
        margin: 40px auto 0px auto;
    }
    h3 {
        font-size: 20px;
        text-align: center;
    }
}
}